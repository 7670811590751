<template>
    <div class="projects-list">
        <DsListItem
            v-for="project in sortedProjects"
            :key="project.id"
            border-style="full"
            clickable
            :class="{
                'active': isProjectActive(project),
                'pulsate presenting': currentDemoProjectId === project.id,
                'idea-used': isProjectFromIdea(project),
            }"
            @click="selectProject(project)"
        >
            <template #leading>
                <aside>
                    <img
                        v-if="project.thumbnail || project.image"
                        :src="project.thumbnail || project.image"
                        :alt="project.name"
                        :class="['project-image', { compact }]"
                    />

                    <div v-else :class="['project-image-placeholder', { compact }]">
                        <DsIcon name="image" />
                    </div>

                    <CommentIndicator
                        v-if="compact && projectCommentCount[project.id]"
                        :comment-count="projectCommentCount[project.id]"
                        compact
                    />

                    <DsTooltip v-if="compact && isProjectHackathonActive(project) && project.rolesWanted.length">
                        <template #reference>
                            <div class="help-indicator">
                                <DsIcon name="life-buoy" />
                            </div>
                        </template>

                        <template #default>
                            Help wanted
                        </template>
                    </DsTooltip>

                    <DsStatus
                        v-if="currentDemoProjectId === project.id"
                        emphasis="high"
                        type="error"
                        class="pulsate"
                    >
                        <DsIcon name="circle-fill" style="--icon-color: white; --icon-size: .5rem" />
                        Live
                    </DsStatus>
                </aside>
            </template>

            <template #title>
                <h4 :class="['project-name', { compact, 'winner': winningProjectIds.includes(project.id) && !isHallOfFamePage }]">
                    <div
                        v-if="winningProjectIds.includes(project.id) && !isHallOfFamePage"
                        class="winner-indicator"
                    >
                        <DsIcon
                            name="award-fill"
                        />
                        Winner!
                    </div>

                    {{ project.name }}
                </h4>
            </template>

            <template #description>
                <template v-if="project.shortDescription" class="project-description">
                    {{ project.shortDescription }}
                </template>

                <p v-if="project.description" class="project-description">
                    {{ project.description }}
                </p>
            </template>

            <template #shortDescription>
                <ProjectTeamMembers :compact="compact" :project="project" />

                <DsInlineAlert
                    v-if="!compact && isProjectHackathonActive(project) && project.rolesWanted.length"
                    leading-icon="life-buoy"
                    type="good"
                    class="help-alert"
                >
                    <strong>Roles wanted</strong>

                    {{ getRolesWanted(project) }}
                </DsInlineAlert>
            </template>

            <template #trailing>
                <div :class="{ 'project-indicators': !compact }">
                    <CommentIndicator
                        v-if="!compact && projectCommentCount[project.id]"
                        :comment-count="projectCommentCount[project.id]"
                    />

                    <DemoIndicator
                        v-if="showDemoIndicator && project.disablePresenting"
                    />
                </div>
            </template>
        </DsListItem>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { roles } from '@/components/project/constants';
import CommentIndicator from '@/components/CommentIndicator';
import DemoIndicator from '@/components/DemoIndicator';
import ProjectTeamMembers from '@/components/ProjectTeamMembers';

export default {
    components: {
        CommentIndicator,
        DemoIndicator,
        ProjectTeamMembers,
    },

    props: {
        compact: Boolean,
        approvedOnly: Boolean,
        winnersFirst: Boolean,
        projects: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            roles,
        };
    },

    computed: {
        ...mapGetters({
            getUser: 'users/getUser',
            getProject: 'projects/getProject',
            getHackathon: 'hackathons/getHackathon',
            winningProjectIds: 'hackathons/winningProjectIds',
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        ...mapState({
            hackathons: ({ hackathons }) => hackathons.hackathons,
            comments: ({ hackathons }) => hackathons.comments,
            demo: ({ hackathons }) => hackathons.demo,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),

        currentDemoProjectId() {
            return this.demo?.projectId;
        },

        projectCommentCount() {
            const projectIds = this.projects.map(({ id }) => id);

            const count = this.comments
                ?.filter(({ projectId }) => projectIds.includes(projectId))
                ?.map(({ projectId, comments }) => ({ [projectId]: comments?.length }));

            return Object.assign({}, ...count);
        },

        sortedProjects() {
            return [
                ...this.winningProjects,
                ...this.filteredProjects,
            ];
        },

        winningProjects() {
            return this.projects.filter(({ id }) => this.winningProjectIds.includes(id));
        },

        filteredProjects() {
            return this.projects.filter(({ id }) => !this.winningProjectIds.includes(id));
        },

        isHallOfFamePage() {
            return this.$route.name === 'hall.of.fame';
        },

        showDemoIndicator() {
            if (!this.$route.name === 'hackathon') return false;

            return this.$route.params?.id === this.activeHackathon?.id;
        },
    },

    methods: {
        isProjectFromIdea(project) {
            return project.hackathonId && project.ideaAuthor && this.$route.name === 'ideas';
        },

        getRolesWanted(project) {
            return project?.rolesWanted?.map((role) => roles[role])?.join(', ');
        },

        isProjectActive(project) {
            return this.$route.params?.id === project?.id;
        },

        isProjectHackathonActive(project) {
            return this.activeHackathon?.id === project?.hackathonId;
        },

        selectProject(project) {
            if (this.isProjectActive(project)) return;

            this.$emit('selected', project);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.active {
    background-color: $color-gray-100;
    position: sticky;
    top: -1px;
    bottom: 0;
    z-index: 2;


    &:hover {
        background-color: $color-gray-200 !important;
    }
}

.idea-used {
    --list-item-title-color: #{$color-green-900};
    --list-item-description-color: #{$color-green};
}

.presenting {
    background-color: $color-green-050 !important;

    &:hover {
        background-color: $color-green-100 !important;
    }
}

.project-image {
    width: 120px;
    border-radius: $border-radius;

    @media($small) {
        width: 80px;
    }

    @media($extra-small) {
        width: 40px;
    }

    &.compact {
        border-radius: calc($border-radius / 2);
        width: 60px;
    }
}

.project-image-placeholder {
    width: 120px;
    height: 100px;
    --icon-size: 40px;
    --icon-color: #{$color-gray-400};
    border: 1px solid $color-gray-300;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background: $color-gray-050;

    @media($small) {
        width: 80px;
    }

    @media($extra-small) {
        width: 40px;
        height: 30px;
        --icon-size: 20px;
        border-radius: calc($border-radius / 2);
    }

    &.compact {
        width: 60px;
        height: 50px;
        border-radius: calc($border-radius / 2);
    }
}

.scroll-wrap {
    height: calc(100vh - 104px);
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.project-name {
    font-size: $font-size-lg;
    font-weight: 400;
    display: flex;
    align-items: center;

    &.winner {
        font-weight: bold;
    }

    &.compact {
        font-size: $font-size-sm;
    }
}

.project-description {
    margin-bottom: $spacing-100;
}

.roles-wanted {
    margin-top: $spacing-200;
}

.demo-icon {
    --icon-color: #{$color-green-300};

    &.disabled {
        --icon-color: #{$color-gray-400};
    }
}

.project-indicators {
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: $spacing-200;

    @media($extra-small) {
        display: none;
    }
}

.winner-indicator {
    color: $color-yellow-900;
    align-items: center;
    float: left;
    margin-right: $spacing-050;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    --icon-color: #{$color-yellow-800};
    --icon-size: 16px;
}

aside {
    display: flex;
    grid-gap: $spacing-100;
    flex-direction: column;
}

.help-indicator {
    display: flex;
    grid-gap: $spacing-050;
    align-items: center;
    width: 100%;
    background-color: $color-green-050;
    padding: $spacing-100;
    border-radius: calc($border-radius / 2);
    --icon-size: #{$icon-size-sm};
    --icon-color: #{$color-green-400};
    justify-content: center;
}

.help-alert {
    margin: $spacing-200 0 0;
}
</style>
