<template lang="html">
    <div>
        <portal to="title">
            Search
        </portal>

        <p v-if="noResults">
            No results
        </p>

        <div class="search-results">
            <section v-if="filteredProjects.length">
                <header>
                    <h4>Projects</h4>
                    <span>{{ filteredProjects.length }} results</span>
                </header>

                <ProjectsList
                    v-if="filteredProjects.length"
                    :projects="filteredProjects"
                    @selected="(project) => $router.push({ name: 'project', params: { id: project.id} })"
                />
            </section>

            <section v-if="filteredHackathons.length">
                <header>
                    <h4>Hackathons</h4>
                    <span>{{ filteredHackathons.length }} results</span>
                </header>

                <DsListItem
                    v-for="hackathon in filteredHackathons"
                    :key="hackathon.id"
                    border-style="full"
                    clickable
                    :title="hackathon.name"
                    @click="$router.push({ name: 'hackathon', params: { id: hackathon.id } })"
                >
                    <template #leading>
                        <img
                            v-if="hackathon.thumbnail || hackathon.image"
                            :src="hackathon.thumbnail || hackathon.image"
                            :alt="hackathon.name"
                            class="hackathon-image"
                        />

                        <div v-else :class="['project-image-placeholder', { compact }]">
                            <DsIcon name="image" />
                        </div>
                    </template>

                    <template #title>
                        {{ hackathon.name }}
                    </template>
                </DsListItem>
            </section>

            <section v-if="filteredUsers.length">
                <header>
                    <h4>Users</h4>
                    <span>{{ filteredUsers.length }} results</span>
                </header>

                <DsListItem
                    v-for="user in filteredUsers"
                    :key="user.uid"
                    clickable
                    border-style="full"
                    @click="$router.push({ name: 'user', params: { id: user.uid } })"
                >
                    <template #leading>
                        <UserAvatar
                            :size="40"
                            :user="user"
                        />
                    </template>

                    <template #title>
                        <p>
                            <DsChip v-if="guestsList.includes(user.email)" class="outline">
                                Guest
                            </DsChip>

                            {{ user.name }}
                        </p>
                    </template>

                    <!-- <template #trailing>
                        <DsIcon name="chevron-right" />
                    </template> -->
                </DsListItem>
            </section>
        </div>
    </div>
</template>

<script>
import ProjectsList from '@/components/ProjectsList';
import { mapState, mapGetters } from 'vuex';
import { roles } from '@/components/project/constants';
import UserAvatar from '@/components/UserAvatar';

export default {
    components: {
        ProjectsList,
        UserAvatar,
    },

    computed: {
        searchTerm() {
            return this.$route.query.q;
        },

        trimmedSearchTerm() {
            return this.searchTerm?.toLowerCase()?.trim();
        },

        noResults() {
            const resultCount = this.filteredHackathons.length + this.filteredProjects.length + this.filteredUsers.length;

            return resultCount === 0 && this.trimmedSearchTerm?.length > 0;
        },

        filteredHackathons() {
            if (!this.trimmedSearchTerm?.length) return [];

            return this.hackathons.filter(({ name }) => name.toLowerCase().includes(this.trimmedSearchTerm));
        },

        filteredProjects() {
            if (!this.trimmedSearchTerm?.length) return [];

            return this.projects.filter((project) => {
                const hasProjectTag = project.tags && project.tags.some((id) => this.getTag(id).name && this.getTag(id).name.toLowerCase().includes(this.trimmedSearchTerm));
                const hasProjectRole = project.rolesWanted && project.rolesWanted.some((role) => roles[role].toLowerCase().includes(this.trimmedSearchTerm));

                return project.name.toLowerCase().includes(this.trimmedSearchTerm) || hasProjectTag || hasProjectRole;
            });
        },

        filteredUsers() {
            return this.trimmedSearchTerm && this.users.filter(({ name }) => name?.toLowerCase()?.includes(this.trimmedSearchTerm));
        },

        ...mapState({
            hackathons: ({ hackathons }) => hackathons.hackathons,
            projects: ({ projects }) => projects.projects,
            users: ({ users }) => users.users,
        }),

        ...mapGetters({
            getTag: 'tags/getTag',
            getAwardByProject: 'projects/getAwardByProject',
            guestsList: 'users/guestsList',
        }),
    },
};
</script>

<style lang="scss" scoped>
.search-results {
    display: flex;
    flex-direction: column;
    gap: $spacing-200;
}

section {
    background-color: $color-white;
    border-radius: $border-radius;
    padding: $spacing-200;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $spacing-200;
    }
}


.hackathon-image {
    width: 100px;
    height: auto;
    border-radius: $border-radius;
}
</style>
